export const ALL_TOOLS = [
  {
    name: "Mortgage Calculator",
    url: "/tools/mortgage-calculator",
    img: "/mortgage_calc_icon.webp",
    alt: "Mortgage calculator icon",
    description: "Work out your estimated repayments!",
  },
  {
    name: "Mortgage Affordability Calculator", // TODO: affordability or mortgage borrowing?
    url: "/tools/mortgage-borrowing-calculator",
    img: "/mortgage_calc_icon.webp",
    alt: "Mortgage calculator icon",
    description: "Work out what you can potentially borrow for your mortgage!",
  },
  {
    name: "Debt Calculator",
    url: "/tools/debt-calculator",
    img: "/card_icon.webp",
    alt: "Debt calculator icon",
    description: "How long will it take to pay your debt off?",
  },
  {
    name: "Bulb Calculator",
    url: "/tools/bulb-calculator",
    img: "/light_bulb_calculator_icon.webp",
    alt: "Bulb calculator icon",
    description: "Work out how much you could save by changing bulbs!",
  },
];

export const ALL_GUIDES = [
  {
    name: "Three Ways To Save Money",
    url: "/guides/three-ways-to-save-money",
    img: "/mortgage_calc_icon.webp",
    alt: "Three Ways To Save Money icon",
    description: "Three top tips to save money!",
  },
];
